/* You can add global styles to this file, and also import other style files */

/* @import "@angular/material/prebuilt-themes/purple-green.css"; */
/*@import "@angular/material/prebuilt-themes/pink-bluegrey.css"; */
/* @import '@angular/material/prebuilt-themes/deeppurple-amber.css';  */

html{
  font-size: var(--mdc-list-list-item-label-text-size, 12px);
  
}

div, label, h1, h2, h3 {
    font-family: Rubik, sans-serif !important; 
    /* Add !important to overwrite all elements */
  }

div.app-page{
    margin-top: 70px;
    margin-left: 20px;
}

@media (min-width:600px){
    div.app-page{
        margin-top: 10px;
        margin-left: 20px;
    
    }
}


div.app-page-large-screen{
    margin-top: 10px;
    margin-left: 20px;

}


html, body, app-root, .app-frame {
    overflow: hidden;
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    background-color: #303030 !important;
       
}

.mat-expansion-panel-body{
    background-color: #303030 !important;
    font-family: Rubik;
  }
  
  .mat-expansion-panel-header-title {
    font-family: Rubik;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content{
    font-family: Rubik;
  }

.mat-mdc-button-base{
    font-family: 'Rubik' !important;
}

.mat-mdc-table{
    font-family: 'Rubik' !important;
}

.mat-tab-label{
  font-size: 10px !important;
  font-family: Rubik;
} 

.active-list-item {
  color: #f9a825 !important; /* Note: You could also use a custom theme */
}

.mat-list-item .mat-list-item-content {
  display: flex !important;
  align-items: center !important;
  font-size: 8px;
}

.list-item-active {
  font-weight: bold;
  color: orange !important;
}


