@use '@angular/material' as mat;
@include mat.elevation-classes();
@include mat.app-background();

/* ======== Angular material custom themes ======== */ 
$my-custom-primary: mat.m2-define-palette(mat.$m2-orange-palette);
$my-custom-accent: mat.m2-define-palette(mat.$m2-blue-palette, 300, 500, A100);
$my-custom-warn: mat.m2-define-palette(mat.$m2-red-palette);

@mixin custom-mat-list-layout() { .mat-list-item .mat-list-item-content { font-size: 12px !important; } }


$my-custom-typography: mat.m2-define-typography-config(
    $font-family: 'Rubik',
  );

  @include mat.typography-hierarchy($my-custom-typography);

$my-custom-theme: mat.m2-define-dark-theme((
    color: (
        primary: $my-custom-primary, 
        accent: $my-custom-accent, 
        warn: $my-custom-warn,
    ), 
    typography: $my-custom-typography,
    density: -1,
));



@include mat.all-component-themes($my-custom-theme);